export default {
    tableFields:   [
        {
            name:      '__component:badge-column',
            title:     '',
            dataClass: 'text-center',
            width:     '4%'
        },
        {
            name:      'user.name',
            title:      'Name',
            sortField: 'name',
            width:     '24%'
        },
        {
            name:      'user.email',
            title:      'Email',
            sortField: 'email',
            width:     '24%'
        },
        {
            name:       'roles.name',
            title:      'Rol',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            width:      "15%",
        },
        {
            name:  'user.created_at',
            title: 'Created',
            width: '20%'
        },
        {
          name: '__slot:active',
          title: 'ACTIONS',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
          width: '24%',
        },
    ],
    sortFunctions: {
        'name': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
    }
}
